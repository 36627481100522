import React, { useRef, useState, useEffect } from "react";
import emailjs from "@emailjs/browser";
import Modal from "./Modal";
import { FaLinkedin, FaGithub } from "react-icons/fa";

const Contact = () => {
  const [loading, setLoading] = useState(false);
  const [successModal, setSuccessModal] = useState(false);
  const nameRef = useRef();
  const emailRef = useRef();
  const messageRef = useRef();
  const subjectRef = useRef();

  useEffect(() => emailjs.init(process.env.REACT_APP_PUBLIC_KEY), []);
  // Add these
  const sendEmail = async (e) => {
    e.preventDefault();
    const serviceId = process.env.REACT_APP_SERVICE_ID;
    const templateId = process.env.REACT_APP_TEMPLATE_ID;

    try {
      console.log(emailRef.current.value);
      setLoading(true);
      await emailjs.send(serviceId, templateId, {
        name: nameRef.current.value,
        email: emailRef.current.value,
        subject: subjectRef.current.value,
        message: messageRef.current.value,
      });
      setSuccessModal(true);
      nameRef.current.value = ""; // Clear the name input field
      emailRef.current.value = ""; // Clear the email input field
      messageRef.current.value = ""; // Clear the message input field
      subjectRef.current.value = "";
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div
      className="flex justify-center items-center"
      style={{ height: "calc(100vh - 5rem)" }}
    >
      <div className="px-5 lg:pr-20">
        <h1 className="pb-5 md:pb-10 hover-cursor">Contact Me</h1>
        <p className="hover-cursor">
          I am interested in a full-time or part-time position as a web
          developer. If you have any questions, please feel free to contact me.
        </p>
        <div className="flex justify-center lg:justify-start gap-5 mt-5">
          <a
            href="https://www.linkedin.com/in/edoardo-lovino/"
            target="_blank"
            rel="noreferrer"
          >
            <FaLinkedin size={30} />
          </a>
          <a href="https://github.com/edoloba" target="_blank" rel="noreferrer">
            <FaGithub size={30} />
          </a>
        </div>
        <div className="w-[100%] mt-[20px]">
          <form onSubmit={sendEmail}>
            <div className="flex justify-start gap-10 mb-2">
              <input
                type="text"
                placeholder="Name"
                name="name"
                required
                className="custom-input"
                ref={nameRef}
              />

              <input
                type="email"
                placeholder="Email"
                name="email"
                required
                className="custom-input"
                ref={emailRef}
              />
            </div>
            <input
              type="text"
              placeholder="Subject"
              name="subject"
              required
              className="custom-input mb-2"
              ref={subjectRef}
            />

            <textarea
              type="text"
              placeholder="Message"
              name="message"
              required
              ref={messageRef}
              className="w-[100%] b-0 min-h-[150px] text-[16px] custom-input"
            ></textarea>

            <button
              type="submit"
              className="text-[20px] tracking-[3px] py-[8px] px-[10px] border-1 float-right mt-[10px] rounded-[4px] align-middle text-center w-full lg:w-[250px] text-[#141414] bg-[#fffffd] cursor-pointer"
              disabled={loading}
            >
              SEND
            </button>
          </form>
        </div>
      </div>
      {successModal && (
        <Modal isOpen={successModal} onClose={() => setSuccessModal(false)} />
      )}
    </div>
  );
};

export default Contact;
