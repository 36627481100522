// import React from "react";
// import {
//     Button,
//   Dialog,
//   DialogHeader,
//   DialogBody,
//   DialogFooter,
// } from "@material-tailwind/react";
 
// const Modal = () => {
//   const [open, setOpen] = React.useState(false);
 
//   const handleOpen = () => setOpen(!open);

//   const now = new Date();
//   const currentHour = now.getHours();

//   if(currentHour >= 5 && currentHour <= 12) {
//     return (
//       <>
//         <Dialog open={open} handler={handleOpen}>
//           <DialogHeader>Your message is coming through</DialogHeader>
//           <DialogBody>
//            Thanks for Contact me, I will get back to you as soon as possible.
//            Have a great morning!
//           </DialogBody>
//           <DialogFooter>
//             <Button variant="gradient" color="green" onClick={handleOpen}>
//               <span>Close</span>
//             </Button>
//           </DialogFooter>
//         </Dialog>
//       </>
//     );
//   } else if (currentHour >= 12 && currentHour <= 18) {
//     return (
//       <>
//         <Dialog open={open} handler={handleOpen}>
//           <DialogHeader>Your message is coming through</DialogHeader>
//           <DialogBody>
//            Thanks for Contact me, I will get back to you as soon as possible.
//            Have a great afternoon!
//           </DialogBody>
//           <DialogFooter>
//             <Button variant="gradient" color="green" onClick={handleOpen}>
//               <span>Close</span>
//             </Button>
//           </DialogFooter>
//         </Dialog>
//       </>
//     );
//   } else if (currentHour >= 18 && currentHour <= 20) {
//     return (
//       <>
//         <Dialog open={open} handler={handleOpen}>
//           <DialogHeader>Your message is coming through</DialogHeader>
//           <DialogBody>
//            Thanks for Contact me, I will get back to you as soon as possible.
//            Have a great evening!
//           </DialogBody>
//           <DialogFooter>
//             <Button variant="gradient" color="green" onClick={handleOpen}>
//               <span>Close</span>
//             </Button>
//           </DialogFooter>
//         </Dialog>
//       </>
//     );
//   } else {
//     return (
//       <>
//         <Dialog open={open} handler={handleOpen}>
//           <DialogHeader>Your message is coming through</DialogHeader>
//           <DialogBody>
//            Thanks for Contact me, I will get back to you as soon as possible.
//            Have a great night!
//           </DialogBody>
//           <DialogFooter>
//             <Button variant="gradient" color="green" onClick={handleOpen}>
//               <span>Close</span>
//             </Button>
//           </DialogFooter>
//         </Dialog>
//       </>
//     );
//   }
 
// }

// export default Modal;

import React from "react";

const Modal = ({ isOpen, onClose }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-80">
      <div className="bg-[#fffffd] text-[#141414] p-4 rounded-lg shadow-lg w-80 cursor-hovered">
        <div className="text-xl font-bold mb-2">Your message has been sent</div>
        <div className="mb-4">
          Thanks for contacting me. I will get back to you as soon as possible.
          <br />
          Have a great time!
        </div>
        <button
          onClick={onClose}
          className="bg-[#141414] text-[#fffffd] px-4 py-2 rounded focus:outline-none float-right"
        >
          Close
        </button>
      </div>
    </div>
  );
};

export default Modal;