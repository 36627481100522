import React, { useState, useEffect } from 'react';
import Typewriter from 'typewriter-effect';
import { Link } from 'react-router-dom';
import Cube from './Cube';

const Home = () => {
  const professions = ['FE Developer', 'Web Designer', "Music Passionate", 'Basket Fan'];
  const [index, setIndex] = useState(0);
  const [mobile, setMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setMobile(window.innerWidth <= 768);
    }
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    }
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      setIndex((prevIndex) => (prevIndex + 1) % professions.length);
    }, 3000); // Change the interval as needed

    return () => clearInterval(interval);
  }, [professions.length]);

  return (
    <div>
    <Link to='/'>
      <h1 className="hover-cursor">Hi! <br className=''></br> I'm Edo,</h1>
      <h2 className='text-[18px] lg:text-[26px] font-bold hover-cursor'>
        <Typewriter
          options={{
            strings: professions,
            autoStart: true,
            loop: true,
          }}
        />
      </h2>
    </Link>
    {mobile ? <Cube /> : null}
    </div>
  );
};

export default Home;





