import React from "react";

const Experience = () => {
  return (
    <div className="pb-10 px-5 lg:pr-20">
      <h1 className="pb-5 lg:pb-10 hover-cursor">Experience</h1>
      <div className="px-0 lg:px-2 flex flex-col lg:flex-row items-start justify-between border-2 border-[#ede7e7] mb-3 w-auto ">
        <div className="w-full lg:w-[40%] border-b-2 border-[#ede7e7] lg:border-b-0 mb-4 lg:mb-0 pl-6 lg:pl-0">
          <p className="font-bold lg:font-normal hover-cursor">Software Developer <br/> Web Designer</p>
          <p className="text-[14px] hover-cursor">
            WL Artworld Solutions <br />February 2023 - Present
          </p>
        </div>
        <div className="w-auto lg:w-[90%]">
          <ul className="list-disc pl-6 lg:border-l-2 border-[#ede7e7] border-l-0">
            <li className="pb-2 hover-cursor">
              Experienced in PHP, JavaScript, Vue.js, Nuxt.js, WordPress,
              LayTheme, REST APIs, Tailwind CSS
            </li>
            <li className="pb-2 hover-cursor">
              Support on domain transfer and website migration to new Hosting
              Provider
            </li>
            <li className="pb-2 hover-cursor">
              Work side by side with top-notch software engineer and UX/UI
              designer on the art field
            </li>
            <li className="pb-2 hover-cursor">
              Work either individually and on team projects and managed customer
              communication
            </li>
            <li className="pb-2 hover-cursor">Managed customer communication</li>
          </ul>
        </div>
      </div>
        <div className="px-0 lg:px-2 flex flex-col lg:flex-row items-start justify-between border-2 border-[#ede7e7] mb-3 w-auto ">
          <div className="w-full lg:w-[40%] border-b-2 border-[#ede7e7] lg:border-b-0 mb-4 lg:mb-0 pl-6 lg:pl-0">
            <p className="font-bold lg:font-normal hover-cursor">
              Full Stack Developer <br /> Apprenticeship
            </p>
            <p className="text-[14px] hover-cursor">
              DCI - Digital Career Institute <br />April 2021 - July
              2022
            </p>
          </div>
          <div className="w-[90%]">
            <ul className="list-disc pl-6 lg:border-l-2 border-[#ede7e7] border-l-0">
              <li className="pb-2 hover-cursor">
                Experienced in JavaScript, React, HTML, CSS, Node.js,
                Express.js, MongoDB
              </li>
              <li className="pb-2 hover-cursor">
                Learned best practices from the industry experts and work in
                real-life production environments
              </li>
              <li className="pb-2 hover-cursor">
                Completed soft skills, such as agile/scrum project management
              </li>
              <li className="pb-2 hover-cursor">
                Work either individually and on team projects and managed
                customer communication
              </li>
              <li className="pb-2 hover-cursor">Took weekly German classes (B1 level)</li>
            </ul>
          </div>
        </div>
      </div>
  );
};

export default Experience;
