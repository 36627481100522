import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import { isMobile } from './utils';
import { useLocation } from 'react-router-dom';

const Cursor = () => {
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [hidden, setHidden] = useState(false);
  const [clicked, setClicked] = useState(false);
  const [hovered, setHovered] = useState(false);

  const location = useLocation();

  useEffect(() => {
    const addEventListeners = () => {
      document.addEventListener("mousemove", onMouseMove);
      document.addEventListener("mouseenter", onMouseEnter);
      document.addEventListener("mouseleave", onMouseLeave);
      document.addEventListener("mousedown", onMouseDown);
      document.addEventListener("mouseup", onMouseUp);
    };

    const removeEventListeners = () => {
      document.removeEventListener("mousemove", onMouseMove);
      document.removeEventListener("mouseenter", onMouseEnter);
      document.removeEventListener("mouseleave", onMouseLeave);
      document.removeEventListener("mousedown", onMouseDown);
      document.removeEventListener("mouseup", onMouseUp);
    };

    const onMouseLeave = () => {
      setHidden(true);
    };

    const onMouseEnter = () => {
      setHidden(false);
    };

    const onMouseDown = () => {
      setClicked(true);
    };

    const onMouseUp = () => {
      setClicked(false);
    };

    const onMouseMove = (e) => {
      setPosition({ x: e.clientX, y: e.clientY });
    };

    const handleHoverEvents = () => {
      document.querySelectorAll("a, h1, .hover-cursor, button").forEach(el => {
        el.addEventListener("mouseover", () => setHovered(true));
        el.addEventListener("mouseout", () => setHovered(false));
      });
    };

    addEventListeners();
    handleHoverEvents();

    return () => {
      removeEventListeners();
      // Remove any other event listeners you might have added
    };
  }, [location]);

  if (isMobile()) {
    return null;
  }

  const cursorClasses = classNames(
    'cursor', {
    'cursor--clicked': clicked,
    'cursor--hidden': hidden,
    'cursor--hovered': hovered
    }
  );

  return (
    <div className={cursorClasses}
      style={{
        left: `${position.x}px`,
        top: `${position.y}px`
      }}
    />
  );
}

export default Cursor;