import React, { useState, useEffect } from "react";
import { TiArrowRightThick } from "react-icons/ti";
import vielmetterImage from "../images/vielmetter.png";
import danielGustavImage from "../images/danielgc.png";
import radioCircolo from "../images/radiocircolo.png";
import jeremy from "../images/jeremyvdh.png";
import { Carousel, Typography, Button } from "@material-tailwind/react";

const Projects = () => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [mobile, setMobile] = useState(window.innerWidth <= 768);


  useEffect(() => {
    const handleResize = () => {
      setMobile(window.innerWidth <= 768);
    }
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    }
  }, []);

  const projectsData = [
    {
      image: vielmetterImage,
      title: "Vielmetter Los Angeles",
      description: "I've played an integral role in orchestrating the smooth migration of websites from Statamic to WordPress, all the while reinvigorating their design essence. Through the dynamic potential of Tailwind CSS and the meticulous artistry of handcrafted vanilla CSS, I led a holistic revamp of the frontend. Additionally, I seamlessly integrated JavaScript animations, infusing vitality into the user journey and lifting the website's aesthetic to new heights.",
      description_mobile:"I've been instrumental in the smooth transition of websites from Statamic to WordPress, revitalizing their design using Tailwind CSS and bespoke vanilla CSS. I've also introduced engaging JavaScript animations to enhance the user experience and elevate the websites' aesthetic appeal.",
      link: "https://vielmetter.com/",
    },
    {
      image: danielGustavImage,
      title: "Daniel Gustav Cramer",
      description: "Daniel Gustav Cramer is a Berlin-based multi-disciplinary conceptual artist. I've been an essential member of the team driving the evolution of the frontend. Collaborating intimately with the artist, I contributed to crafting a seamless design using the dynamic duo of Nuxt.js and Tailwind CSS. Together, we sculpted an impeccable digital canvas that perfectly encapsulates the artist's vision. (soon to be released)",
      description_mobile: "I've been a pivotal contributor to the evolution of the frontend for Berlin-based conceptual artist Daniel Gustav Cramer, employing Nuxt.js and Tailwind CSS to create a seamless design that perfectly embodies the artist's vision.",
      link: "https://danielgustavcramer.com/",
    }, 
    {
      image: radioCircolo,
      title: "Radiocircolo",
      description: "Radiocircolo represents a collaborative initiative dedicated to delivering podcasts that break free from the confines of musical boundaries. I spearheaded the end-to-end development of the entire website, sculpting its frontend design while seamlessly integrating the functionalities of both a local API and the Mixcloud API. My role encompassed harmonizing these diverse elements into a unified, cohesive digital experience. (soon to be released)",
      description_mobile:"I led the comprehensive development of the Radiocircolo website, unifying its frontend design and seamlessly integrating local and Mixcloud APIs to deliver a collaborative podcast platform that transcends musical boundaries.",
      link: "https://radio-circolo-jzwtnzpun-edoloba.vercel.app/",
    }, 
    {
      image: jeremy,
      title: "Jeremy van der Hage",
      description: "I had the opportunity to collaborate closely with the talented Belgian film director, Jeremy van der Hagen, to create a captivating online portfolio that truly reflects his unique vision and cinematic prowess. In this endeavor, I assumed complete responsibility for the entire development process, harnessing the power of the LayTheme framework for WordPress and curating a visually stunning showcase that seamlessly integrated personalized vanilla CSS and Javascript features.",
      description_mobile:"I collaborated with Belgian film director Jeremy van der Hagen to develop an online portfolio, taking full ownership of the development process and seamlessly integrating personalized CSS and JavaScript features through the LayTheme framework for WordPress",
      link: "https://jeremyvdh.com/",
    }
  ];

  const handleArrowClick = (direction) => {
    const totalSlides = projectsData.length;
    let nextSlide;

    if (direction === "next") {
      nextSlide = (currentSlide + 1) % totalSlides;
    } else {
      nextSlide = (currentSlide - 1 + totalSlides) % totalSlides;
    }

    setCurrentSlide(nextSlide);
  };

  return ( 
    <>
      {!mobile ?  
      <div className="flex justify-center items-center">
    <div className="py-10 px-5 lg:pr-10">
      <h1 className="pb-4 hover-cursor">Projects</h1>
      <div className="card-container flex justify-between gap-10 ">
        {/* Map through projectsData and display the current slide */}
        <div className="flex flex-col">
          <div className="xl:w-40rem xl:h-50rem 2xl:w-58rem 2xl:h-50rem">
          <img src={projectsData[currentSlide].image} alt={projectsData[currentSlide].title} width="600" height="500" />
          <h2 className="pt-8 font-bold hover-cursor">{projectsData[currentSlide].title}</h2>
          <p className="pt-4 hover-cursor">{projectsData[currentSlide].description}</p>
          <a href={projectsData[currentSlide].link} target="_blank" rel="noreferrer">
            <button className="p-2 text-[#141414] bg-[#fffffd] float-right mt-4 hover-cursor">
              Visit Website
              </button>
          </a>
          </div>
        </div>
        <TiArrowRightThick
          className="w-20 h-10 align-middle cursor-pointer hidden lg:block hover-cursor"
          onClick={() => handleArrowClick("next")}
        />
      </div>
    </div>
    </div>
      : 
    <Carousel className="rounded-sm"  style={{ height: "calc(100vh - 5rem)" }} navigation={false}>
      {projectsData.map((project, index) => (
        <div className="relative h-full w-full" key={index}>
          <img
            src={project.image}
            alt={project.title}
            className="h-full w-full object-contain"
          />
            <div className="absolute inset-0 grid h-full w-full place-items-center bg-[#211f1fb3]">
              <div className="w-3/4 text-center md:w-2/4">
                <Typography
                variant="h1"
                color="white"
                className="mb-4 text-xl"
                >
                {project.title}
                </Typography>
                <Typography
                variant="lead"
                color="white"
                className="mb-12"
                >
                {project.description_mobile}
                </Typography>
                <div className="flex justify-center gap-2">
                <a href={project.link} target="_blank" rel="noreferrer">
                <Button size="md" color="white">
                Visit Website
                </Button>
                </a>
                </div>
              </div>
          </div>  
        </div>
        ))}
      </Carousel>
      }
      </>
  );
};

export default Projects;
