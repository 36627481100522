import React from "react";

const About = () => {
  const downloadCV = () => {
    fetch("Edoardo Lovino - CV.pdf")
      .then((res) => res.blob())
      .then((blob) => {
        const fileURL = window.URL.createObjectURL(blob);
        let alink = document.createElement("a");
        alink.href = fileURL;
        alink.download = "Edoardo Lovino - CV.pdf";
        alink.click();
      });
  };

  return (
    <div
      className="flex-none md:flex justify-center"
      style={{ height: "calc(100vh - 5rem)" }}
    >
      <div className="">
        <div className="px-5 mb-10 lg:pr-20">
          <h1 className="text-[53px] mt-0 hover-cursor">About</h1>
          <p className="para-about hover-cursor">
            I am an enthusiastic and driven junior frontend developer and web
            designer with a hunger for creativity and a genuine interest in
            culture. I thrive on challenges and am eager to bring my strong
            teamwork and fast learning abilities to your dynamic team. I am
            excited to showcase my skills and demonstrate my commitment to
            excellence.
          </p>
          <p className="para-about hover-cursor">
            I am eager to learn new technologies and improve my skills. I am
            always looking for opportunities to grow and expand my knowledge,
            and I am excited about the prospect of working with a team that
            values continuous learning.
          </p>
          <p className="para-about hover-cursor">
            I have decided to change my career path in 2020 and jumped into the
            field of Fullstack Web Development. I am enchanted by the IT world
            and just like an enchantment I enjoy unravelling hidden solutions.
            Throughout my life, I have gained great experience in work and
            non-work situations, shaping my person and maturing, knowing how to
            adapt quickly in new environments. I believe that being flexible in
            everyday life greatly helps to have an elastic mind, an undisputed
            value to be agile in overcoming obstacles and finding solutions, not
            by chance one of the most important qualities in the world of
            coding. As my next career step I want to find a company where I can
            combine both: bring in my experience + newly gained knowledge and
            expand my expertise.
          </p>
          <p className="para-about hover-cursor">
            In my free time, I enjoy listening to music, producing it and
            sharing it. When I'm not goofing around with my synthesizers and
            daw, I'm actively involved in various radio stations, including{" "}
            <a
              href="https://www.rbl.media/"
              target="_blank"
              rel="noreferrer"
              className="underline"
            >
              RBL Berlin
            </a>
            ,{" "}
            <a
              href="https://refugeworldwide.com/"
              target="_blank"
              rel="noreferrer"
              className="underline"
            >
              Refuge Worldwide Radio
            </a>{" "}
            and 
            <a
              href="https://www.radioalhara.net/"
              target="_blank"
              rel="noreferrer"
              className="underline"
            >{" "}
               Radio Alhara
            </a>
            . At these stations, I host a variety of radio shows that feature a
            wide range of music and talks.
          </p>
          <button
            type="sumbit"
            className="text-[20px] tracking-[3px] py-[8px] px-[10px] border-1 float-right my-[10px] rounded-[4px] align-middle text-center w-[250px] text-[#141414] bg-[#fffffd] mt-4"
            onClick={downloadCV}
          >
            DOWNLOAD CV
          </button>
        </div>
      </div>
    </div>
  );
};

export default About;
