import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Navbar from "./components/Navbar";
import React, { useState, useEffect } from "react";
import Home from "./components/Home";
import About from "./components/About";
import Projects from "./components/Projects";
import Experience from "./components/Experience";
import Contact from "./components/Contact";
import Cube from "./components/Cube";
import Cursor from "./components/Cursor";

function App() {
  const [mobile, setMobile] = useState(window.innerWidth <= 768);


  useEffect(() => {
    const handleResize = () => {
      setMobile(window.innerWidth <= 768);
    }
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    }
  }, []);

  

  
  return (
    <Router>
      <Cursor />
    <div className="flex flex-col lg:flex-row items-center h-screen justify-start">
      
      <div className="px-5 lg:pl-20 w-full lg:w-[35%] flex flex-col-reverse lg:flex-col lg:flex-none">
      {mobile ? null : <Home /> }
        <Navbar  />
      </div>
      <div className="flex-grow">
        <Routes>
          <Route
            path="/"
            element={mobile ? <Home /> : <Cube /> }
          />
          <Route path="/about" element={<About id="about" />} />
          <Route path="/projects" element={<Projects id="projects" />} />
          <Route path="/experience" element={<Experience />} />
          <Route path="/contact" element={<Contact />} />
        </Routes>
  
      </div>
    </div>
  </Router>
  );
}

export default App;

