import React from 'react'
import {
    FaReact,
    FaHtml5,
    FaCss3Alt,
    FaJsSquare,
    FaGitAlt,
    FaVuejs,
  } from "react-icons/fa";

const Cube = () => {
  return (
    <div className="stage-cube-cont transform -translate-x-1/2 -translate-y-1/2 ">
      <div className="">
        <div className="cubespinner">
          <div className="face1">
            <FaReact className="text-[#61DBFB]" />
          </div>
          <div className="face2">
          <FaGitAlt className="text-[#3E2C00]" />
          </div>
          <div className="face3">
            <FaJsSquare className="text-[#F0DB4F]" />
          </div>
          <div className="face4">
            <FaCss3Alt className="text-[#2965f1]" />
          </div>
          <div className="face5">
          <FaHtml5 className="text-[#E44D26]" />
          </div>
          <div className="face6">
            <FaVuejs className="text-[#34495E]" />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Cube