import React, { useState } from "react";
import { Link } from "react-router-dom";

const Navbar = () => {
  const [isNavOpen, setIsNavOpen] = useState(false);

  const toggleMenu = () => {
    setIsNavOpen(!isNavOpen);
  };

  return (
    <>
      <div
        className={`hamburger-menu block lg:hidden float-right ${
          isNavOpen ? "open" : ""
        }`}
      >
        <div className="hamburger-icon" onClick={toggleMenu}>
          <div className="bar"></div>
          <div className="bar"></div>
          <div className="bar"></div>
        </div>
        <div className={`menu-overlay ${isNavOpen ? "open" : ""}`}>
          <div className="menu-items text-[#211F1F]" onClick={() => setIsNavOpen(false)}>
            {/* Insert your navigation links here */}
            <Link to="/" className="text-md p-5 font-bold">
              Home
            </Link>
            <Link to="/about" className="text-md p-5 font-bold">
              About
            </Link>
            <Link to="/projects" className="text-md p-5 font-bold">
              Projects
            </Link>
            <Link to="/experience" className="text-md p-5 font-bold">
              Experience
            </Link>
            <Link to="/contact" className="text-md p-5 font-bold">
              Contact
            </Link>
          </div>
        </div>
      </div>
      <nav className="lg:flex lg:flex-col justify-around pt-0 lg:pt-10 hidden ">
        <div className="nav relative lg:hidden w-[10px] h-10 lg:w-10 lg:h-[10px] my-0 mx-5 lg:mx-0 lg:my-5 bg-[#ede7e7] transition-all lg:hover:w-[130px] lg:hover:h-[10px] ease-in-out duration-300 cursor-pointer">
          <Link to="/">
            <p className="absolute pt-2 opacity-0 transition-opacity duration-300 hover:opacity-1 text-[20px] hover-cursor">
              Home
            </p>
          </Link>
        </div>
        <div className="nav relative w-[10px] h-10 lg:w-10 lg:h-[10px] my-0 mx-5 lg:mx-0 lg:my-5 bg-[#ede7e7] transition-all lg:hover:w-[130px] lg:hover:h-[10px] ease-in-out duration-300 cursor-pointer">
          <Link to="/about">
            <p className="absolute pt-2 opacity-0 transition-opacity duration-300 hover:opacity-1 text-[20px] hover-cursor">
              About
            </p>
          </Link>
        </div>
        <div className="nav relative w-[10px] h-10 lg:w-10 lg:h-[10px] my-0 mx-5 lg:mx-0 lg:my-5 bg-[#ede7e7] transition-all lg:hover:w-[130px] lg:hover:h-[10px] ease-in-out duration-300 cursor-pointer">
          <Link to="/projects">
            <p className="absolute pt-2 opacity-0 transition-opacity duration-300 hover:opacity-1 text-[20px] hover-cursor">
              Projects
            </p>
          </Link>
        </div>
        <div className="nav relative w-[10px] h-10 lg:w-10 lg:h-[10px] my-0 mx-5 lg:mx-0 lg:my-5 bg-[#ede7e7] transition-all lg:hover:w-[130px] lg:hover:h-[10px] ease-in-out duration-300 cursor-pointer">
          <Link to="/experience">
            <p className="absolute pt-2 opacity-0 transition-opacity duration-300 hover:opacity-1 text-[20px] hover-cursor">
              Experience
            </p>
          </Link>
        </div>

        <div className="nav relative w-[10px] h-10 lg:w-10 lg:h-[10px] my-0 mx-8 lg:mx-0 lg:my-5 bg-[#ede7e7] transition-all lg:hover:w-[130px] lg:hover:h-[10px] ease-in-out duration-300 cursor-pointer">
          <Link to="/contact">
            <p className="absolute pt-2 opacity-0 transition-opacity duration-300 hover:opacity-1 text-[20px] hover-cursor">
              Contact
            </p>
          </Link>
        </div>
      </nav>
    </>
  );
};

export default Navbar;
